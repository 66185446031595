import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import MarqueeSlider from './MarqueeSlider';
import ImageBg from "./../../../res/images/bg_struggle.png";
import ImgIcon from "./../../../res/images/ic_speechUnderstanding.svg";
import ImgIcon2 from "./../../../res/images/ic_interactions.svg";
import ImgIcon3 from "./../../../res/images/ic_memory.svg";
import ImgBtnIcon from "./../../../res/images/ic_arrow_right.svg";
import { HashLink } from 'react-router-hash-link';
import Aos from "aos";
import "aos/dist/aos.css";
import { HBTurq } from '../../../config/theme';


const Grid = styled.div`
  /* border: 1px yellow dashed; */
  grid-area: 3 / 1 / 4 / 2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  /* padding-top: 40px; */
  padding-bottom: 100px;
  background-color: white;
  padding-top: 100px;
  height: 100%;
  ${media.lessThan("medium")`
  padding-top: 40px;
  `}

  ${media.between("medium", "large")`
  margin-top: 100px;
  `}

  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  align-self: center;
  ${media.lessThan("medium")`
  width: 100%
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const Title = styled.h3`
    font-family: Montserrat;
    font-weight: 800;
    font-size: 50px;
    text-align: left;
    /* line-height: 40px; */
    z-index: 1;
    color: black;
    justify-self: center;
    align-self: center;
    margin-bottom: 40px;
    margin-top: 10px;
    ${media.lessThan("medium")`
        font-size: 28px;
        text-align: center;
        margin-top: 0px;
    `}

    ${media.between("medium", "large")`
      font-size: 38px;
      margin-top: 0px;
    `}
`;

  const AboutText = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #15B1B2;
    align-self: center;
    font-size: 25px;
    letter-spacing: 0.13em;
    ${media.lessThan("medium")`
        font-size: 18px;
        width: 90%;
        text-align: center;
  `}

    ${media.between("medium", "large")`
    font-size: 20px;
  `}
  `;

const CardContainer = styled.div`
  /* border: 1px blue dashed; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
    max-width: 1440px;
    ${media.lessThan("medium")`
        flex-direction: column;
        align-content: center;
        margin-top: 0px;
  `}
    ${media.between("medium", "large")`
  `}
`;

const Card = styled.div`
    /* border: 1px red dashed; */
    display: flex;
    flex-direction: column;
    border-radius: 38px;
    padding: 20px;
    width: 29%;
    ${media.lessThan("medium")`
        width: 320px;
        align-self: center;
        margin-top: 30px;
        padding: 20px;
  `}
    ${media.between("medium", "large")`
        padding: 10px;
  `}
`;

const BtnLearnHow = styled.button`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  /* border: none; */
  width: 200px;
  height: 55px;
  border-radius: 9px;
  /* padding-top: 2px; */
  background: transparent;
  align-self: center;
  justify-content: center;
  ${media.lessThan("medium")`
  height: 50px;
`}

  ${media.between("medium", "large")`
`}
`;

const ButtonText = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  color: #000;
  align-self: center;
`;

const Icon = styled.img`
    height: 150px;
    width: 250px;
    /* align-self: center; */
    ${media.lessThan("medium")`
    `}
    ${media.between("medium", "large")`
    width: 200px;
    `}
`;

const CardTitleText = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    /* text-align: center; */
    color: black;
    margin-top: 30px;
    /* line-height: 38px; */
    ${media.lessThan("medium")`
    `}
    ${media.between("medium", "large")`
    font-size: 18px;
    line-height: 24px;
    `}
`;

const CardText = styled.p`
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 50px;
    /* text-align: center; */
    color: #4f4f4f;
    line-height: 38px;
    margin-bottom: 20px;
    ${media.lessThan("medium")`
  `}
    ${media.between("medium", "large")`
    line-height: 30px;
  `}
`;

const ArrowIcon = styled.img`
  width: 28px;
  height: 28px;
  margin-left: 10px;
  align-self: center;
`;

export default class BenefitsOutcomes extends Component {

  state = {
        move: false
    }

    componentDidMount(){
        Aos.init()

        setTimeout(function() {
            var resizeEvent = new Event("resize");
            window.dispatchEvent(resizeEvent);
        }.bind(this), 1000)

      }

  render() {
  return (
        <Grid id='benefits'>
          <TitleContainer>
            <AboutText>WHY HEAR BEYOND?</AboutText>
            <Title>BENEFITS & OUTCOMES</Title>
          </TitleContainer>
          <CardContainer>
            <Card>
                <Icon src={ImgIcon} />
                <CardTitleText>IMPROVES SPEECH UNDERSTANDING</CardTitleText>
                <CardText>Build an enhanced ability to separate what you are trying to hear from the noisy world around you.</CardText>
                <HashLink to="/#download"  style={{ textDecoration: 'none' }}>
                <BtnLearnHow>
                  <ButtonText>Get started</ButtonText>
                  <ArrowIcon src={ImgBtnIcon} />
                </BtnLearnHow>
                </HashLink>
                </Card>
                
            <Card>
                <Icon src={ImgIcon2} />
                <CardTitleText>ENJOY INTERACTIONS MORE</CardTitleText>
                <CardText>Improving your communication will help your social interactions be more enjoyable and less work.</CardText>
            </Card>
            <Card>
            <Icon src={ImgIcon3} />
            <CardTitleText>GROW AUDITORY MEMORY</CardTitleText>
                <CardText>Developing the Auditory Memory builds confidence in social interactions and opportunities.</CardText>
            </Card>
          </CardContainer>
          </Grid>
          )
        }
      }
      