import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import MarqueeSlider from './MarqueeSlider';
import ImageBg from "./../../../res/images/bg_struggle.png";
import ImageBgPhone from "./../../../res/images/bg_struggle_phone.png";
import ImgIcon from "./../../../res/images/ic_noisyRoom.svg";
import ImgIcon2 from "./../../../res/images/ic_repeatYourself.svg";
import ImgIcon3 from "./../../../res/images/ic_remember.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import { HBTurq } from '../../../config/theme';


const Grid = styled.div`
  /* border: 1px yellow dashed; */
  background-image: url(${ImageBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-bottom: 100px;
  background-color: black;
  padding-top: 100px;
  height: 100%;
  ${media.lessThan("medium")`
    padding-top: 100px;
    background-image: url(${ImageBgPhone});
  `}

  ${media.between("medium", "large")`
  margin-top: 100px;
  `}

  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
    /* border: 1px red dashed; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    align-self: center;
  ${media.lessThan("medium")`
    width: 90%
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const Title = styled.h3`
    font-family: Montserrat;
    font-weight: 700;
    font-size: 50px;
    text-align: left;
    line-height: 40px;
    z-index: 1;
    color: white;
    text-shadow: 1px 1px 4px #000;
    justify-self: center;
    align-self: center;
    margin-bottom: 40px;
    margin-top: 10px;
    ${media.lessThan("medium")`
        font-size: 28px;
        margin-bottom: 10px;
        text-align: center;
        margin-top: 0px;
    `}

    ${media.between("medium", "large")`
      font-size: 38px;
      margin-top: 0px;
    `}
`;

  const AboutText = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: ${HBTurq};
    text-shadow: 1px 1px 4px #000;
    align-self: center;
    letter-spacing: 0.13em;
    font-size: 25px;
    ${media.lessThan("medium")`
        font-size: 18px;
  `}

    ${media.between("medium", "large")`
      font-size: 20px;
  `}
  `;

const CardContainer = styled.div`
  /* border: 1px red dashed; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
    max-width: 1440px;
    ${media.lessThan("medium")`
        flex-direction: column;
        align-content: center;
        margin-top: 0px;
  `}
    ${media.between("medium", "large")`
  `}
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    background: black;
    box-shadow: 0px 0px 20px rgba(11, 242, 242, 0.52);
    border-radius: 38px;
    padding: 50px;
    width: 30%;
    ${media.lessThan("medium")`
        width: 90%;
        max-width: 400px;
        align-self: center;
        margin-top: 30px;
  `}
    ${media.between("medium", "large")`
        padding: 30px;
  `}
`;

const Icon = styled.img`
  /* border: 1px red dashed; */
    width: 80%;
    height: 150px;
    /* align-self: center; */
    /* margin-top: 10px; */
    ${media.lessThan("medium")`
      max-width: 200px;
  `}
    ${media.between("medium", "large")`
    width: 90%;
  `}
`;

const Icon2 = styled.img`
    width: 260px;
    height: 140px;
    align-self: center;
    margin-top: 10px;
`;


const CardText = styled.p`
    /* width: 80%; */
    font-family: Montserrat;
    font-weight: 300;
    font-size: 21px;
    line-height: 50px;
    /* text-align: center; */
    color: #fff;
    margin-top: 60px;
    line-height: 38px;
    align-self: center;
    ${media.lessThan("medium")`
        margin-top: 20px;
        font-size: 16px;
  `}
    ${media.between("medium", "large")`
        margin-top: 20px;
        font-size: 16px;
  `}
`;

export default class HereToHelp extends Component {

  state = {
        move: false
    }

    componentDidMount(){
        Aos.init()

        setTimeout(function() {
            var resizeEvent = new Event("resize");
            window.dispatchEvent(resizeEvent);
        }.bind(this), 1000)

      }

  render() {
  return (
        <Grid id='pain'>
          <TitleContainer >
            <AboutText >WE'RE HERE TO HELP</AboutText>
            <Title>DO YOU STRUGGLE FROM</Title>
          </TitleContainer>
          <CardContainer>
            <Card
              data-aos="fade-up" 
              data-aos-duration="1000" 
              data-aos-easing="ease-in-sine"
            >
                <Icon src={ImgIcon} />
                <CardText>Difficulty understanding words, especially against background noise or in a crowd.</CardText>
            </Card>
            <Card
              data-aos="fade-up" 
              data-aos-duration="2000" 
              data-aos-easing="ease-in-sine"
            >
                <Icon src={ImgIcon2} />
                <CardText>Asking others to frequently repeat themselves because they're always mumbling.</CardText>
            </Card>
            <Card
              data-aos="fade-up" 
              data-aos-duration="3000" 
              data-aos-easing="ease-in-sine"
            >
                <Icon src={ImgIcon3} />
                <CardText>Difficulty remembering people's names, memorizing phone numbers, recalling instructions.</CardText>
            </Card>
          </CardContainer>
          </Grid>
          )
        }
      }
      