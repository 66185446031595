import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import MarqueeSlider from './MarqueeSlider';
import ImageBg from "./../../../res/images/bg_struggle.png";
import ImageBgPhone from "./../../../res/images/bg_struggle_phone.png";
import ImgIcon from "./../../../res/images/ic_soundCircle.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { HBTurq } from '../../../config/theme';


const Grid = styled.div`
  /* border: 1px yellow dashed; */
  background-image: url(${ImageBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  grid-area: 4 / 1 / 5 / 2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-bottom: 100px;
  background-color: black;
  padding-top: 100px;
  height: 100%;
  ${media.lessThan("medium")`
    padding-top: 100px;
    background-image: url(${ImageBgPhone});
  `}

  ${media.between("medium", "large")`
  margin-top: 100px;
  `}

  ${media.greaterThan("large")`
  `}
`;


const CardContainer = styled.div`
  /* border: 1px red dashed; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 20px;
    width: 90%;
    max-width: 1440px;
    ${media.lessThan("medium")`
        flex-direction: column;
        align-content: center;
        margin-top: 0px;
  `}
    ${media.between("medium", "large")`
  `}
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: 38px;
    /* padding: 40px; */
    width: 25%;
    height: 200px;
    justify-content: center;
    ${media.lessThan("medium")`
        width: 300px;
        align-self: center;
        margin-top: 30px;
  `}
    ${media.between("medium", "large")`
    width: 30%;
  `}
`;

const Icon = styled.img`
    width: 60px;
    height: 60px;
    align-self: center;
    margin-top: 10px;
`;

  const AboutText = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: ${HBTurq};
  text-shadow: 1px 1px 4px #000;
  align-self: center;
  letter-spacing: 0.13em;
  font-size: 25px;
  ${media.lessThan("medium")`
      font-size: 18px;
  `}

  ${media.between("medium", "large")`
  font-size: 20px;
  `}
  `;

const CardText = styled.p`
    font-family: Montserrat;
    font-weight: 600;
    font-size: 28px;
    text-align: center;
    color: black;
    /* margin-top: 30px; */
    ${media.lessThan("medium")`
    font-size: 22px;
  `}
    ${media.between("medium", "large")`
    font-size: 24px;
  `}
`;

export default class Options extends Component {

  state = {
        move: false
    }

    componentDidMount(){
        Aos.init()

        setTimeout(function() {
            var resizeEvent = new Event("resize");
            window.dispatchEvent(resizeEvent);
        }.bind(this), 1000)

      }

  render() {
  return (
        <Grid id='games'>
          <AboutText>AUDITORY BRAIN TRAINING GAMES</AboutText>
          <CardContainer>
          <MarqueeSlider/>
          </CardContainer>
          </Grid>
          )
        }
      }
      

    //   <Card
    //   data-aos="fade-up" 
    //   data-aos-duration="1000" 
    //   data-aos-easing="ease-in-sine"
    // >
    //     <CardText>CONSULTATION</CardText>
    // </Card>
    // <Card
    //   data-aos="fade-up" 
    //   data-aos-duration="1000" 
    //   data-aos-easing="ease-in-sine"
    // >
    //     <CardText>CONSULTATION</CardText>
    // </Card>
    // <Card
    //   data-aos="fade-up" 
    //   data-aos-duration="1000" 
    //   data-aos-easing="ease-in-sine"
    // >
    //     <CardText>CONSULTATION</CardText>
    // </Card>