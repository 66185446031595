import React, { Component } from 'react';
import * as Bootstrap from "react-bootstrap";
import styled from "styled-components";
import media from "styled-media-query";
import Logo from "./../../../res/images/Logo_HearBeyond.png";
import { Link } from 'react-router-dom'
import { HBTurq } from '../../../config/theme';
import { HashLink } from 'react-router-hash-link';
import TagManager from 'react-gtm-module'

const Container = styled.div`
  border-bottom: 1px ${HBTurq} solid;
`;

const HeaderLogoImg = styled.img`
  height: 51px;
  width: auto;
  margin-left: 50px;
  ${media.lessThan("medium")`
  margin-left: 5px;
  `}
`;

const NavListContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;

  ${media.lessThan("medium")`
  flex-direction: column;
  `}

  ${media.between("medium", "large")`
  `}

  ${media.greaterThan("large")`
  `}
`;

const IconContainer = styled.div`
/* border: 1px green dashed; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-self: center;
  width: 300px;
  height: 60px;
  align-content: center;
  ${media.between("medium", "large")`
  /* flex-direction: column; */
  `}

  ${media.greaterThan("large")`
  `}
`;

const List = styled.li`
display: flex;
    justify-content: center;
  margin: 0;
  padding: 0;
  ${media.lessThan("medium")`
  margin-top: 30px;
  `}
`;

const NavItemTitle = styled.p`
  /* border: 1px blue dashed; */
  font-family: 'Montserrat', sans-serif;
  font-size: 1em;
  text-decoration: none;
  margin-right: 70px;
  color: white;
  font-weight: medium;
  text-shadow: 2px 2px 8px black;
  padding-top: 20px;
  &:hover {
    text-shadow: 0px 0px 8px turquoise;
  }
  
  ${media.lessThan("medium")`
  padding-top: 0px;
  font-size: 1rem
  margin-right: 0px;
  `}

  ${media.between("medium", "large")`
    font-size: 1rem
  `}

  ${media.greaterThan("large")`
  `}
`;


export default class BootNav extends Component {

  constructor(props) {
    super(props);

    this.state = {
      navExpanded: false
    };
  }

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  }

  setNavClose = () => {
    this.setState({ navExpanded: false });
  }

  handleContactLink = () => {
        TagManager.initialize(tagContactLink)
    }

    render() {
        return (
            <Container id="top">
                <Bootstrap.Navbar collapseOnSelect onToggle={this.setNavExpanded} expanded={this.state.navExpanded} className="navbar-custom" expand="lg" variant="dark" style={{paddingBottom: "20px", paddingTop: "20px"} }>
                    <Bootstrap.Navbar.Brand> 
                      <Link to="/">
                      <HeaderLogoImg src={Logo}>
                        </HeaderLogoImg>
                      </Link>           
                    </Bootstrap.Navbar.Brand>

                        <Bootstrap.Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Bootstrap.Navbar.Collapse id="responsive-navbar-nav">
                          <Bootstrap.Nav className="mr-auto">
                          </Bootstrap.Nav>

                          <Bootstrap.Nav onClick={this.setNavClose} style={{justifyContent: "center"}}>
                          <NavListContainer>

                          <List>
                            <HashLink to="/#benefits"  style={{ textDecoration: 'none' }}>
                              <NavItemTitle onClick={this.handleContactLink}>BENEFITS</NavItemTitle>
                            </HashLink>
                          </List>
                          <List>
                            <HashLink to="/#games"  style={{ textDecoration: 'none' }}>
                              <NavItemTitle onClick={this.handleContactLink}>GAMES</NavItemTitle>
                            </HashLink>
                          </List>
                          <List>
                            <HashLink to="/#download"  style={{ textDecoration: 'none' }}>
                              <NavItemTitle onClick={this.handleContactLink}>DOWNLOAD</NavItemTitle>
                            </HashLink>
                          </List>
                          <List>
                            <HashLink to="/#contact"  style={{ textDecoration: 'none' }}>
                              <NavItemTitle onClick={this.handleContactLink}>CONTACT</NavItemTitle>
                            </HashLink>
                          </List>
              
                          </NavListContainer>
                          </Bootstrap.Nav>
                          </Bootstrap.Navbar.Collapse>
                          </Bootstrap.Navbar>
                          </Container>
                          )
                        }
                      }

    const tagContactLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'navbar_link_contact'
      }
    }


