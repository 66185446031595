import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import MarqueeSlider from './MarqueeSlider';
import ImageBg from "./../../../res/images/bg_struggle.png";
import ImageBgPhone from "./../../../res/images/bg_struggle_phone.png";
import ImageDownloadApp from "./../../../res/images/DownloadApp.svg"
import ImgIcon from "./../../../res/images/ic_soundCircle.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { HBTurq } from '../../../config/theme';
import TagManager from 'react-gtm-module'


const Grid = styled.div`
  /* border: 1px yellow dashed; */

  /* grid-area: 6 / 1 / 7 / 2; */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-bottom: 80px;
  background-color: black;
  padding-top: 80px;
  height: 100%;
  ${media.lessThan("medium")`
    padding-top: 40px;
    padding-bottom: 40px;
    background-image: url(${ImageBgPhone});
  `}

  ${media.between("medium", "large")`
  `}

  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
    /* border: 1px red dashed; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    align-self: center;
  ${media.lessThan("medium")`
    width: 70%
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const Title = styled.h3`
    font-family: Montserrat;
    font-weight: 700;
    font-size: 50px;
    text-align: left;
    line-height: 40px;
    z-index: 1;
    color: white;
    text-shadow: 1px 1px 4px #000;
    justify-self: center;
    align-self: center;
    margin-bottom: 20px;
    margin-top: 10px;
    ${media.lessThan("medium")`
        font-size: 28px;
        margin-bottom: 10px;
        text-align: center;
        margin-top: 0px;
    `}
    ${media.between("medium", "large")`
        font-size: 38px;
      margin-top: 0px;
    `}
`;

  const AboutText = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: ${HBTurq};
    text-shadow: 1px 1px 4px #000;
    align-self: center;
    letter-spacing: 0.13em;
    font-size: 25px;
    ${media.lessThan("medium")`
        font-size: 18px;
  `}

    ${media.between("medium", "large")`
    font-size: 20px;
  `}
  `;

const PrivacyTag = styled.a`
  text-decoration: none;
  align-self: center;
  ${media.lessThan("medium")`
  `}
  
  ${media.between("medium", "large")`
  align-self: center;
`}
`;

const PrivacyTag2 = styled.a`
  text-decoration: none;
  align-self: center;
  ${media.lessThan("medium")`
  `}
  
  ${media.between("medium", "large")`
  align-self: center;
`}
`;

const ButtonContainer = styled.div`
  /* border: 1px red dashed; */
  width: auto;
  display: flex;  
  flex-direction: row;
  justify-content: center;
  ${media.lessThan("medium")`
    flex-direction: column;
`}
  ${media.between("medium", "large")`
`}
`;

const BtnDownloadApple = styled.img`
  /* border: 1px green dashed; */
  width: auto;
  height: 50px;
  border-radius: 8px;
  margin-right: 5px;
  transition-duration: 0.5s;
    &:hover{
        opacity: 0.9;
        transform: scale(1.05);
        }
  ${media.lessThan("medium")`
  margin-left: 0px;
  height: 50px;
`}

  ${media.between("medium", "large")`
  margin-left: 0px;
`}
`;

const BtnDownloadGoogle = styled.img`
  /* border: 1px yellow dashed; */
  width: auto;
  height: 75px;
  border-radius: 8px;
  transition-duration: 0.5s;
    &:hover{
        opacity: 0.9;
        transform: scale(1.05);
        }
  ${media.lessThan("medium")`
  margin-top: 10px;
  height: 70px;

`}

  ${media.between("medium", "large")`
  margin-left: 0px;
`}
`;
export default class GetStarted extends Component {

  state = {
        move: false
    }

    componentDidMount(){
        Aos.init()

        setTimeout(function() {
            var resizeEvent = new Event("resize");
            window.dispatchEvent(resizeEvent);
        }.bind(this), 1000)

      }


      handleiOSLink = () => {
        TagManager.initialize(tagiOSLink)
      }
      handleAndroidLink = () => {
        TagManager.initialize(tagAndroidLink)
      }

  render() {
  return (
        <Grid>
          <TitleContainer
            data-aos="fade-in" 
              data-aos-duration="1000" 
              data-aos-easing="ease-in-sine"
          >
            <AboutText>GET STARTED</AboutText>
            <Title>DOWNLOAD HEAR BEYOND</Title>
          </TitleContainer>
          <ButtonContainer
            data-aos="fade-in" 
            data-aos-duration="1000" 
            data-aos-easing="ease-in-sine"
          > 
                <div style={{alignSelf: 'center'}} onClick={this.handleiOSLink}> 
                  <PrivacyTag
                      target="_blank" 
                      href="https://apps.apple.com/ca/app/hear-beyond/id1529830800">
                      <BtnDownloadApple src={ImageDownloadApp}/> 
                    </PrivacyTag>
                  </div>  
                  <div style={{alignSelf: 'center'}}  onClick={this.handleAndroidLink}> 
                    <PrivacyTag2
                      target="_blank" 
                      href="https://play.google.com/store/apps/details?id=com.hearbeyondandroid">
                      <BtnDownloadGoogle src="https://play.google.com/intl/en_us/badges/images/generic/en-play-badge.png" /> 
                    </PrivacyTag2>
                  </div>  
                </ButtonContainer>
          </Grid>
          )
        }
      }
      
      const tagiOSLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'hearbeyond_link_ios'
      }
    }

    const tagAndroidLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
        tag: 'hearbeyond_link_android'
      }
    }