import React from "react";
import { useForm } from "react-hook-form";
import styled from 'styled-components'
import media from "styled-media-query";
import { Parallax } from "react-parallax";
import ImageBg from "./../../../res/images/bg_CodeMountain_bottom2.jpg";
import { HBTurq } from "../../../config/theme";
import TagManager from 'react-gtm-module'

const Grid = styled.div`
  /* border: 1px yellow dashed; */
  /* grid-area: 3 / 1 / 4 / 2; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100%;
  background-color: black;
  `;

const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`;

const BlackStrip = styled.div`
  background-color: #000000;
  height: 80px;
`;

const TurqLine = styled.div`
  border-bottom: 1px ${HBTurq} solid;
  
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  z-index: 1;
  color: white;
  text-shadow: 1px 1px 4px #000;
  justify-self: center;
  text-align: center;
  align-self: center;
  /* margin-top: 20px; */
  /* margin-bottom: 40px; */
  /* width: 90%; */
  ${media.lessThan("medium")`
  font-size: 1.5em;
  `}

  ${media.between("medium", "large")`
  align-self: center;
  `}
`;

const Description = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 200;
  line-height: 20px;
  z-index: 1;
  color: white;
  text-shadow: 1px 1px 4px #000;
  justify-self: center;
  text-align: center;
  align-self: center;
  /* margin-top: 20px; */
  margin-bottom: 40px;
  ${media.lessThan("medium")`
  width: 330px;
  /* font-size: 1.5em; */
  `}

  ${media.between("medium", "large")`
  align-self: center;
  `}
`;

const BlackContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  justify-content: center;
  align-content: center;
  background: black;
  padding-top: 30px;
  padding-right: 80px;
  padding-left: 80px;
  margin-bottom: 0px;
  margin-top: 40px;
  width: 600px;
  height: auto;
  justify-self: center;
  align-self: center;
  flex-direction: column;
  border-radius: 20px;
  ${media.lessThan("medium")`
    width: 80%;
    flex-direction: column;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 40px; 
    padding-top: 10px;
    margin-bottom: 0px;
    margin-top: 50px;
  `}

  ${media.between("medium", "large")`
    padding-bottom: 40px; 
    margin-bottom: 0px;
    margin-top: 20px;
    width: auto;
  `}

  ${media.greaterThan("large")`
  `}
`;

const Form = styled.form`
    /* border: 1px blue dashed; */
    width: auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    `;


const Input2 = styled.input`
    /* border: 1px blue dashed; */
    width: 453.23px;
    height: 49.65px;
    outline: none!;
    border: 1px solid ${HBTurq};
    outline: none;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.1em;
    font-weight: 400;
    background-color: #000000;
    box-sizing: border-box;
    padding: 10px;
    :focus {
        border: 3px solid ${HBTurq};
      }
    ${media.lessThan("medium")`
      width: 260px;
      align-self: center;
    `}
    ${media.between("medium", "large")`
    width: 300px;
      align-self: center;
    `}
    ${media.greaterThan("large")`
    /* margin-left: 200px; */
    `}
`;

const Textarea = styled.textarea`
    resize: none;
    width: 453.23px;
    height: 173px;
    outline: none!;
    border: 1px solid ${HBTurq};
    outline: none;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.1em;
    font-weight: 400;
    background-color: #000000;
    box-sizing: border-box;
    padding: 10px;
    :focus {
        border: 3px solid ${HBTurq};
      }
    ${media.lessThan("medium")`
    width: 260px;
    `}
    ${media.between("medium", "large")`
    width: 300px;
    `}
    ${media.greaterThan("large")`
    `}
`;


const Button2 = styled.button`
    /* border: 1px blue dashed; */
    outline: none;
    width: 250px;
    height: 55px;
    color: ${HBTurq};
    background: black;
    border: 2px solid ${HBTurq};
    border-radius: 30px;
    box-shadow: 0px 0px 5px ${HBTurq};
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 18px;
    align-self: center;
    margin-top: 44px;
    transition-duration: 0.5s;
    &:hover{
        background-color: #6EFAFF;
        color: #000;
        opacity: 0.9;
        transform: scale(1.05);
        }
    ${media.lessThan("medium")`
    /* margin-top: 15px; */
    `}
    ${media.between("medium", "large")`
      /* margin-top: 15px; */
    `}
    ${media.greaterThan("large")`
      /* margin-left: 10px; */
    `}
`;

const Error = styled.p`
    /* border: 1px blue dashed; */
    color: red;
    margin-top: 8px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 129.5%;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
`;

const HiddenError = styled(Error)`
    /* border: 1px blue dashed; */
    color: transparent;
`;


const Success = styled.p`
    /* border: 1px blue dashed; */
    color: green;
    margin-top: 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 129.5%;
    letter-spacing: 0.05em;
    width: 255.55px;
    align-self: center;
    text-align: center;
    ${media.lessThan("medium")`
      align-self: center;
    `}
    ${media.between("medium", "large")`
      align-self: center;
    `}
`;

const HiddenSuccess = styled(Success)`
    /* border: 1px blue dashed; */
    color: transparent;
`;


 export default function ContactUsDelete() {

    const { 
        register, 
        handleSubmit, 
        reset,
        formState: { errors, isSubmitSuccessful }
    } = useForm({reValidateMode: "onSubmit"});

    const onSubmit = async (data) => {

      try {
        let result = await fetch(
          "https://10ww8j15w1.execute-api.us-east-2.amazonaws.com/prod/sendcontactus",
          {
            method: "post",
            mode: "no-cors",
            headers: {
              Accept: "application/json",
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              fullname: data.fullname,
              company: "Data Delete Request",
              email: data.email,
              message: data.message,
            }),
          }
        );

        reset({ fullname: '', company: '', email: '', message: '' });
        TagManager.initialize(tagMessageSent)
      } catch (e) {
        console.log("BIG ERROR", e);
      }
    };

  return (
    <Grid>
          <Container>
            <BlackContainer>
            {/* <TextContainer> */}
                <Title>Delete Account Request</Title>
                <Description>Send us a message to delete your account and / or data.</Description>
            {/* </TextContainer> */}
                <Form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    {/* <Label>NAME</Label> */}
                    <Input2 type="text" name="fullname" placeholder="Name" {...register('fullname', {required: true, maxLength: 20})} />
                    {errors.fullname ? <Error>Name required</Error> :<HiddenError>1</HiddenError>}

                    {/* <Label>EMAIL</Label> */}
                    <Input2 type="text" name="email" placeholder="Email" {...register('email', {required: true, maxLength: 30, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} />
                    {errors.email ? <Error>Valid email required</Error>:<HiddenError>1</HiddenError>}

                    {/* <Label>MESSAGE</Label> */}
                    <Textarea type="textarea" rows={6} name="message" placeholder="Message" {...register('message', {maxLength: 200})} />

                    <Button2 type="submit">SEND</Button2>
                    {(isSubmitSuccessful) ? <Success>Message Sent</Success> : <HiddenSuccess>Message Sent</HiddenSuccess>}
                </Form>
            </BlackContainer>
        </Container>
      <BlackStrip />
      <TurqLine />
      </Grid>
  );
}

const tagMessageSent = {
  gtmId: global.config.gtmId,
  dataLayer: {
      tag: 'hearbeyond_message_sent'
  }
}