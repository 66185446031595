import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  withRouter
} from "react-router-dom";
import './config';
import { Reset } from "styled-reset";
import { ThemeProvider } from 'styled-components';
import * as theme from "./config/theme"
import BootNav from "./ui/component/App/NavBar";
import Footer from "./ui/component/App/Footer";
import HearBeyondLayout from "./ui/component/HearBeyond/HearBeyondLayout";
import PrivacyPolicy from "./ui/component/HearBeyond/PrivacyPolicy";
import TermsOfService from "./ui/component/HearBeyond/TermsOfService";
import ContactUsDelete from "./ui/component/HearBeyond/ContactUsDelete";


function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}
const ScrollToTop = withRouter(_ScrollToTop)


// http://hearbeyond.world.s3-website.us-east-2.amazonaws.com/

function App() {
  return (
    <div>
      <Reset />
      <ThemeProvider theme={theme}>
          <Router>
          <ScrollToTop>
            <BootNav/>    
            <Switch>
                <Route path="/privacypolicy">
                  <PrivacyPolicy />
                </Route>
                <Route path="/termsofservice">
                  <TermsOfService />
                </Route>
                <Route path="/deletedata">
                  <ContactUsDelete />
                </Route>
                <Route path="/">
                  <HearBeyondLayout />
                </Route>
              </Switch>

            </ScrollToTop>
          </Router>
      </ThemeProvider>
    </div>

  );
}

export default App;
