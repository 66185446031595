import { Spinner } from "react-bootstrap"
import styled from "styled-components"

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const StyledSpinner = styled(Spinner)`
  position: fixed;
  color: white;
`
