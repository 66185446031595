import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import MarqueeSlider from './MarqueeSlider';
import ImageBg from "./../../../res/images/bg_struggle.png";
import ImgIcon from "./../../../res/images/ic_quote.png";
import Aos from "aos";
import "aos/dist/aos.css";
import { HBTurq } from '../../../config/theme';


const Grid = styled.div`
  /* border: 1px yellow dashed; */
  grid-area: 5 / 1 / 6 / 2;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  /* padding-top: 40px; */
  /* padding-bottom: 100px; */
  background-color: white;
  padding-top: 60px;
  height: 100%;
  ${media.lessThan("medium")`
  padding-top: 40px;
  `}

  ${media.between("medium", "large")`
  `}

  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  align-self: center;
  ${media.lessThan("medium")`
  width: 100%
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const Title = styled.h3`
    font-family: Montserrat;
    font-weight: 800;
    font-size: 50px;
    text-align: left;
    line-height: 40px;
    z-index: 1;
    color: black;
    justify-self: center;
    align-self: center;
    margin-bottom: 40px;
    margin-top: 10px;
    ${media.lessThan("medium")`
        font-size: 28px;
        text-align: center;
        margin-top: 0px;
    `}

    ${media.between("medium", "large")`
    font-size: 38px;
      margin-top: 0px;
    `}
`;

  const AboutText = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #15B1B2;
    align-self: center;
    font-size: 25px;
    letter-spacing: 0.13em;
    ${media.lessThan("medium")`
        font-size: 18px;
        width: 90%;
        text-align: center;
  `}

    ${media.between("medium", "large")`
    font-size: 20px;
  `}
  `;

const CardContainer = styled.div`
  /* border: 1px blue dashed; */
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
    background: linear-gradient(#15b1b2 0%, #0b5959 100%);
    justify-content: center;
    ${media.lessThan("medium")`
        flex-direction: column;
        align-content: center;
        margin-top: 0px;
    `}
    ${media.between("medium", "large")`
    `}
`;

const CardTextContainer = styled.div`
    /* border: 1px red dashed; */
    display: flex;
    flex-direction: row;
    max-width: 1440px;
    justify-content: space-evenly;
    padding-top: 30px;
    padding-bottom: 30px;
    ${media.lessThan("medium")`
        flex-direction: column;
        padding-top: 0px;
        padding-bottom: 0px;
    `}
    ${media.between("medium", "large")`
    `}
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 38px;
    padding: 20px;
    width: 29%;
    ${media.lessThan("medium")`
        width: 320px;
        align-self: center;
        margin-top: 20px;
        padding: 20px;
  `}
    ${media.between("medium", "large")`
        padding: 10px;
  `}
`;

const Icon = styled.img`
    width: 60px;
    height: 60px;
    align-self: center;
`;

const CardTitleText = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    color: white;
    margin-top: 30px;
    line-height: 38px;
    ${media.lessThan("medium")`
    `}
    ${media.between("medium", "large")`
    font-size: 18px;
    line-height: 24px;
    `}
`;

const CardText = styled.p`
    font-family: Montserrat;
    font-weight: 400;
    font-size: 15px;
    line-height: 50px;
    text-align: center;
    color: white;
    line-height: 38px;
    ${media.lessThan("medium")`
  `}
    ${media.between("medium", "large")`
    line-height: 30px;
  `}
`;

export default class UserStories extends Component {

  state = {
        move: false
    }

    componentDidMount(){
        Aos.init()

        setTimeout(function() {
            var resizeEvent = new Event("resize");
            window.dispatchEvent(resizeEvent);
        }.bind(this), 1000)

      }

  render() {
  return (
        <Grid>
          <TitleContainer>
            <AboutText>LEARNING OUTCOMES</AboutText>
            <Title>FROM HEAR BEYOND</Title>
          </TitleContainer>
          <CardContainer>
            <CardTextContainer>
                <Card
                  data-aos="fade-in" 
                  data-aos-duration="1000" 
                  data-aos-easing="ease-in-sine"
                >
                    <Icon src={ImgIcon} />
                    <CardTitleText>DISCOVER SOUND</CardTitleText>
                    <CardText>
                    Designed to creatively explore and enhance how your brain interacts with the sonic world surrounding you in everyday life.
                    </CardText>

                </Card>
                <Card
                  data-aos="fade-in" 
                  data-aos-duration="1000" 
                  data-aos-easing="ease-in-sine"
                >
                    <Icon src={ImgIcon} />
                    <CardTitleText>CALIBRATE LISTENING</CardTitleText>
                    <CardText>
                    An intuitive ear training app that will guide you into becoming deeply attuned to your ears through games, exercises and exploration.
                    </CardText>

                </Card>
                <Card
                  data-aos="fade-in" 
                  data-aos-duration="1000" 
                  data-aos-easing="ease-in-sine"
                >
                    <Icon src={ImgIcon} />
                    <CardTitleText>ENHANCE PROCESSING</CardTitleText>
                    <CardText>
                    Strengthening your Auditory Brain leads to an enhanced ability to separate what you are trying to hear from the noisy environment around you. 
                    </CardText>
                </Card>
            </CardTextContainer>         
          </CardContainer>
          </Grid>
          )
        }
      }
      