import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../../res/images/Logo_HearBeyond.png"
import { Link, withRouter } from "react-router-dom"
import Interweave from 'interweave';
import { SpinnerContainer, StyledSpinner } from "./../Custom/Spinner"

const Grid = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  /* width: 882px; */
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-self: center;
  background: 'black';
`

const Container = styled.div`
  /* border: 1px green dashed; */
  max-width: 990px;
  min-height: 1000px;
  display: flex;
  width: 70%;
  height: auto;
  background: 'black';
  flex-direction: column;
  align-self: center;
  ${media.lessThan("medium")`
        width: 90%; 
    `}
  ${media.between("medium", "large")`
        width: 80%;
    `}
    ${media.greaterThan("large")`
  `}
`

const Logo = styled.img`
  width: 202px;
  height: 57px;
  margin-top: 27px;
  /* align-self: center; */
  transition-duration: 0.5s;
  :hover {
    transform: scale(1.2);
  }
`

const Title = styled.h1`
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  margin-top: 46px;
  letter-spacing: -0.01em;
  color: #ffffff;
  /* align-self: center; */
  ${media.lessThan("medium")`
    `}
  ${media.between("medium", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const BlurbText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23.4px;
  color: white;
  margin-top: 5px;
  ${media.lessThan("medium")`
    font-size: 16px;
    `}
  ${media.between("medium", "large")`
    font-size: 18px;
    `}
`

const Button = styled.div`
  width: 255.55px;
  height: 69.62px;
  margin-top: 44px;
  margin-bottom: 44px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  border-radius: 36px;
  /* start of Hover code */
  transition-duration: 0.5s;
  :hover {
    background-color: #ffffff;
  }
  /* end of hover code */
  ${media.lessThan("medium")`
      align-self: center;
    `}
  ${media.between("medium", "large")`
      align-self: center;
    `}
`

const BtnBack = styled.div`
  /* border: 1px red dashed; */
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0px;
  margin-top: 30px;
  cursor: pointer;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 6px;
  ${media.lessThan("medium")`
    `}
  ${media.between("medium", "large")`
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  /* line-height: 129.5%; */
  text-align: center;
  letter-spacing: 0.05em;
  color: #ffffff;
  align-self: center;
  justify-self: center;
  transition-duration: 0.5s;
  :hover {
    color: #041129;
  }
`

const Icon = styled.img`
  width: 6px;
  height: 11px;
  margin-right: 10px;
  align-self: center;
  transition-duration: 0.5s;
  color: white;
  ${media.lessThan("medium")`
    `}
  ${media.between("medium", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

class PrivacyPolicy extends Component {

state = {
    data : "",
    isLoading: true,
    isHovering: false,
    showSpinner: true
  };


componentWillMount(){

  setTimeout(() => {
    fetch('https://www.iubenda.com/api/terms-and-conditions/58201931')
      .then(response => response.json())
      .then(data => this.setState({data: data, isLoading: false, showSpinner: false }));
   }, 1000);
}

  render() {
    
    return (
      <Grid>
        <Container>

          <Title></Title>
          <SpinnerContainer>{this.state.showSpinner && <StyledSpinner animation='border' />}</SpinnerContainer>
            {this.state.isLoading ? 
              null
            : 
            <BlurbText>
              <Interweave content={this.state.data.content} />
            </BlurbText>} 

    
          
        </Container>
      </Grid>
    )
  }
}

export default withRouter(PrivacyPolicy)
