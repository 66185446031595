import React, { Component, useEffect } from 'react'
import styled from 'styled-components'
import media from "styled-media-query";
import BannerBlurb from './BannerBlurb';
import HereToHelp from './HereToHelp';
import BenefitsOutcomes from './BenefitsOutcomes';
import Options from './Options';
// import TrainYourEars from './TrainYourEars';
import ImageBg from "./../../../res/images/bg_CodeMountain_top2.jpg";
import ImageBgPhone from "./../../../res/images/bg_CodeMountain_topPhone.jpg";
import ImageLogo from "./../../../res/images/Logo_HearBeyond.png"
import ImageLogoPhone from "./../../../res/images/Logo_HearBeyond_Tech.png"
import ImagePhone from "./../../../res/images/iPhone_Library_Games.png";
import ImageDownloadApp from "./../../../res/images/DownloadApp.svg"
import ContactUs from './ContactUs';
import SubscribeEmail from './SubscribeEmail';
import TagManager from 'react-gtm-module'
import { HBTurq } from '../../../config/theme';
import UserStories from './UserStories';
import GetStarted from './GetStarted';
import SpaceHole from './../Lottie/SpaceHole'
import Footer from './../App/Footer'

const GridContainer = styled.div`
  display: grid;
  grid-template: auto auto auto auto auto auto auto auto / 100%;
  overflow: hidden;
`;

const BottomDiv = styled.div`
/* border: 1px red dashed; */
  grid-area: 6 / 1 / 7 / 2;
  display: flex;
  flex-direction: column;
  background-image: url(${ImageBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Grid = styled.div`
/* border: 1px red dashed; */
  grid-area: 1 / 1 / 2 / 2;
  width: 100%;
  max-width: 1440px;
  height: 800px;
  justify-self: center;
  display: flex;
  flex-direction: row;
  padding-bottom: 120px;
  ${media.lessThan("medium")`
    height: 100vh;
    padding-bottom: 0px;
    justify-content: center;
    flex-direction: column-reverse;
;  `}
  ${media.between("medium", "large")`
  flex-direction: column-reverse;
  `}
  ${media.greaterThan("large")`
  `}
`;

const TitleContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 60px;
  ${media.lessThan("medium")`
    justify-content: center;
    height: 300px;
    align-self: center;
    margin-left: 0px;
  `}
  ${media.between("medium", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`;

const KeywordText = styled.h2`
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  justify-self: center;
  font-size: 20px;
  color: #E3E3E3;
  text-shadow: 1px 1px 4px #000;
  margin-bottom: 20px;
  ${media.lessThan("medium")`
    font-size: 15px;
`}
  ${media.between("medium", "large")`
`}
`;

const TitleText = styled.h1`
  text-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  justify-self: center;
  font-size: 38px;
  color: #FFF;
  text-shadow: 1px 1px 4px #000;
  line-height: 34px;
  margin-top: 20px;
  ${media.lessThan("medium")`
    font-size: 20px;
    margin-left: 0px;
    margin-top: 10px;
    font-weight: 600;
    line-height: 24px;
`}
  ${media.between("medium", "large")`
`}
`;

const TitleText2 = styled(TitleText)`
    font-weight: 700;
    color: ${HBTurq};
    font-size: 40px;
    ${media.lessThan("medium")`
    font-size: 26px;
    font-weight: 700;
`}
`;

const PrivacyTag = styled.a`
  text-decoration: none;
  align-self: center;
  ${media.lessThan("medium")`
  `}
  
  ${media.between("medium", "large")`
  align-self: center;
`}
`;

const PrivacyTag2 = styled.a`
  text-decoration: none;
  align-self: center;
  ${media.lessThan("medium")`
  `}
  
  ${media.between("medium", "large")`
  align-self: center;
`}
`;

const ButtonContainer = styled.div`
  /* border: 1px red dashed; */
  /* height: 58px; */
  width: auto;
  display: flex;  
  flex-direction: row;
  margin-top: 20px;
  ${media.lessThan("medium")`
    /* flex-direction: column; */
    margin-top: 20px;
`}
  ${media.between("medium", "large")`
`}
`;

const BtnDownloadApple = styled.img`
  /* border: 1px green dashed; */
  width: auto;
  height: 60px;
  border-radius: 8px;
  margin-right: 5px;
  transition-duration: 0.5s;
    &:hover{
        opacity: 0.9;
        transform: scale(1.05);
        }
  ${media.lessThan("medium")`
  margin-left: 0px;
  height: 45px;
`}

  ${media.between("medium", "large")`
  margin-left: 0px;
`}
`;

const BtnDownloadGoogle = styled.img`
  /* border: 1px yellow dashed; */
  width: auto;
  height: 90px;
  border-radius: 8px;
  transition-duration: 0.5s;
    &:hover{
        opacity: 0.9;
        transform: scale(1.05);
        }
  ${media.lessThan("medium")`
  margin-top: 0px;
  height: 65px;

`}

  ${media.between("medium", "large")`
  margin-left: 0px;
`}
`;

const BackgroundImage = styled.div`

  grid-area: 1 / 1 / 2 / 2;
  background-image: url(${ImageBg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100vw;
  height: 100vh;
  /* opacity: 40%; */
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
  }
  ${media.lessThan("medium")`
    background-image: url(${ImageBgPhone});
`}
`;

const SpaceHoleAnim = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  justify-self: flex-end;
  width: 500px;
  /* opacity: 1.0; */
  opacity: 1;
  transition: opacity 2s 1s;

  div:hover {
  opacity: 0;
}

  ${media.lessThan("medium")`
    width: 300px;
    align-self: center;
    justify-self: center;  
  `}
    ${media.between("medium", "large")`
    width: 300px;
    margin-left: 60px;
  `}
  `;

export default class DvorakLayout extends Component {

  componentDidMount(){
    TagManager.initialize(tagPageVisit)
  }

  handleiOSLink = () => {
    TagManager.initialize(tagiOSLink)
  }
  handleAndroidLink = () => {
    TagManager.initialize(tagAndroidLink)
  }

  render() {
  
    return (
      <GridContainer>
        <Grid>
          <BackgroundImage/>
          <TitleContainer>
            <KeywordText>GROW AUDITORY MEMORY</KeywordText>
            <TitleText>TRAIN YOUR AUDITORY BRAIN. </TitleText>
            <TitleText>CUT THROUGH THE NOISE.</TitleText>
            <TitleText2>HEAR BEYOND.</TitleText2>
              <ButtonContainer> 
                <div style={{alignSelf: 'center'}} onClick={this.handleiOSLink}> 
                  <PrivacyTag
                      target="_blank" 
                      href="https://apps.apple.com/ca/app/hear-beyond/id1529830800">
                      <BtnDownloadApple src={ImageDownloadApp}/> 
                    </PrivacyTag>
                  </div>  
                  <div style={{alignSelf: 'center'}}  onClick={this.handleAndroidLink}> 
                    <PrivacyTag2
                      target="_blank" 
                      href="https://play.google.com/store/apps/details?id=com.hearbeyondandroid">
                      <BtnDownloadGoogle src="https://play.google.com/intl/en_us/badges/images/generic/en-play-badge.png" /> 
                    </PrivacyTag2>
                  </div>  
                </ButtonContainer>
          </TitleContainer>
          <SpaceHoleAnim>
               {/* <SpaceHole/> */}
            </SpaceHoleAnim>
        </Grid>
        <HereToHelp/>
        <BenefitsOutcomes/>
        <Options/>
        <UserStories />
          <GetStarted />
          <ContactUs />
          <Footer />
        </GridContainer>
        )
      }
    }
    
    const tagPageVisit = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'hearbeyond_page_visit'
      }
    }

    const tagiOSLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
          tag: 'hearbeyond_link_ios'
      }
    }

    const tagAndroidLink = {
      gtmId: global.config.gtmId,
      dataLayer: {
        tag: 'hearbeyond_link_android'
      }
    }


    
    
/**
 * TODO
 * 
 * NAVBAR
 * ScrollSpy hooked up to NavBar
 * Logo should animate
 * Tab header icon is incorrect
 * 
 * Icons for the Struggles
 * More and better copy DO YOU STRUGGLE WITH
 * 
 * Icons for Benefits and outcomes
 * More and better copy
 * Hook up Get Started button
 * 
 * Specific Learning outcomes copy
 * Make Store Icons bigger to match the Landing Page
 * 
 */